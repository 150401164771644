import axios from 'axios';
import { useStore } from './../store/store';
import LodgixOAuth2 from './OAuth2';
import { accessToken, refreshToken } from '@/services/OAuth2';
import * as Sentry from '@sentry/capacitor';

const urlBase = process.env.VUE_APP_URL_BASE;

export default (url = urlBase + '/o') => {
    const store = useStore();
    const api = axios.create({
        baseURL: url,
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
        },
    });

    api.interceptors.request.use(async (config) => {
        if (accessToken.value) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            config.headers!.Authorization = `Bearer ${accessToken.value}`;
        }
        return config;
    });

    api.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (error.response?.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                if (!refreshToken.value) {
                    return Promise.reject(error);
                }
                try {
                    //console.log('Refreshing token due to invalid access token:', originalRequest.url);
                    const didRefresh = await LodgixOAuth2.refresh();
                    if (!didRefresh) {
                        // failed to refresh token, now logging out
                        Sentry.captureMessage(
                            'Failed to refresh token, refresh method returned false. Logging user out.'
                        );
                        LodgixOAuth2.logout();
                    }
                    originalRequest.headers.Authorization = `Bearer ${accessToken.value}`;
                    return api(originalRequest);
                } catch (e) {
                    Sentry.captureMessage('Failed to refresh token due to try/catch block. Logging user out.');
                    LodgixOAuth2.logout();
                    return Promise.reject(e);
                }
            }
            return Promise.reject(error);
        }
    );

    return api;
};
