import { GenericOAuth2 as OAuth2Client } from '@capacitor-community/generic-oauth2';
//import { OAuth2Client } from '@byteowls/capacitor-oauth2';
import { useStore } from '@/store/store';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import AppAPI from './AppAPI';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { Intercom } from '@capacitor-community/intercom';
import { ref } from 'vue';
import * as Sentry from '@sentry/capacitor';
import AppFunctions from './AppFunctions';
import { housekeeperGroup, intercomSupportAccess } from '@/types/accessGroups';
import router from '@/router';
import { SplashScreen } from '@capacitor/splash-screen';

const urlBase = process.env.VUE_APP_URL_BASE;

export const accessToken = ref(
    (
        await SecureStoragePlugin.get({ key: 'accessToken' }).catch((error) => {
            console.log(error);
        })
    )?.value || ''
);
export const refreshToken = ref(
    (
        await SecureStoragePlugin.get({ key: 'refreshToken' }).catch((error) => {
            console.log(error);
        })
    )?.value || ''
);

export async function updateAuthTokens(newAccessToken: any, newRefreshToken: any) {
    SecureStoragePlugin.set({
        key: 'accessToken',
        value: newAccessToken,
    });

    SecureStoragePlugin.set({
        key: 'refreshToken',
        value: newRefreshToken,
    });

    refreshToken.value = newRefreshToken;
    accessToken.value = newAccessToken;
}

//lodgix app info
const oauth2Options = {
    authorizationBaseUrl: urlBase + '/o/authorize/',
    accessTokenEndpoint: urlBase + '/o/token/',
    resourceUrl: urlBase + '/o/api/userinfo/user/',
    logsEnabled: false,
    PKCE_REQUIRED: false,

    web: {
        appId: 'p7DMYI5yMgfCV7crTi4nmRAXiz6XgNkjdbl7xZus',
        responseType: 'code',
        pkceEnabled: true,
        //redirectUrl: window.location.origin,
        redirectUrl: process.env.VUE_APP_REDIRECT_URL,
        windowOptions: 'height=800,left=0,top=0',
        //windowTarget: '_blank',
    },
    android: {
        appId: 'p7DMYI5yMgfCV7crTi4nmRAXiz6XgNkjdbl7xZus',
        pkceEnabled: true,
        responseType: 'code',
        redirectUrl: 'com.lodgix.reservationapp://oauth',
    },
    ios: {
        appId: 'p7DMYI5yMgfCV7crTi4nmRAXiz6XgNkjdbl7xZus',
        pkceEnabled: true,
        responseType: 'code',
        redirectUrl: 'com.lodgix.reservationapp://oauth',
    },
};

const oauth2RefreshOptions = {
    appId: 'p7DMYI5yMgfCV7crTi4nmRAXiz6XgNkjdbl7xZus',
    accessTokenEndpoint: urlBase + '/o/token/',
    refreshToken: 'refreshToken.value',
};

let id = '';
let subscriber_id = '';
let email = '';
let name = '';
let lastName = '';
let loginType = '';

let lastCalled = Date.now() - 1000; // Initialize to 1 second ago
let isRefreshing = false;
const queue: Array<(value: boolean) => void> = [];

export default {
    authenticateWithLodgix: async () => {
        const store = useStore();
        try {
            const response = await OAuth2Client.authenticate(oauth2Options);
            accessToken.value = response['access_token'];
            id = response['id'];
            subscriber_id = response['subscriber_id'];
            email = response['email'];
            name = response['first_name'];
            lastName = response['last_name'];
            loginType = response['groups'][0]['name'];
            store.changeScope(response['access_token_response'].scope);

            try {
                refreshToken.value = response['access_token_response'].refresh_token;
            } catch (error) {
                console.log('Warning: ' + error);
            }

            name.concat(lastName);

            await updateAuthTokens(accessToken.value, refreshToken.value);

            SecureStoragePlugin.set({
                key: 'loginType',
                value: loginType,
            });

            SecureStoragePlugin.set({
                key: 'lang',
                value: 'en',
            });

            store.changeId(id);
            store.changeSubId(subscriber_id);
            store.changeEmail(email);
            store.changeLoginType(loginType);
            store.changeLang('en');
            store.changePushNotificationOpenId('');

            //load in user info
            try {
                await AppFunctions.getUserInfo(true);
            } catch (error) {
                //console.log(error);
            }

            try {
                await AppAPI.logStatistic();
            } catch (error) {
                //console.log(error);
            }

            //set up intercom verification
            if (Capacitor.getPlatform() === 'web') {
                return loginType;
            } else if (intercomSupportAccess() || housekeeperGroup()) {
                try {
                    Intercom.registerIdentifiedUser({ email: store.UserEmail });
                    const hash = await AppAPI.getIntercomHash(Capacitor.getPlatform());
                    Intercom.setUserHash({ hmac: hash.data.code });
                    Intercom.updateUser({
                        name: store.UserFirstName + ' ' + store.UserLastName,
                        customAttributes: { account_type: loginType },
                    });
                } catch (error) {
                    //console.log(error);
                }
            }
        } catch (error: any) {
            if (error.includes('TypeError: undefinded is not an object')) {
                return 'BAD_ACCOUNT_LOGIN';
            }

            return error;
        }

        return loginType;
    },

    refresh: async () => {
        if (Capacitor.getPlatform() === 'web') {
            return false;
        }

        return new Promise((resolve) => {
            queue.push(resolve);

            const now = Date.now();
            if (now - lastCalled < 1000 || isRefreshing) {
                return;
            }

            lastCalled = now;
            isRefreshing = true;

            (async () => {
                let result = false;
                try {
                    oauth2RefreshOptions.refreshToken = refreshToken.value;
                    const response = await OAuth2Client.refreshToken(oauth2RefreshOptions);
                    const access_token = response['access_token'];
                    const refresh_token = response['refresh_token'];
                    await updateAuthTokens(access_token, refresh_token);
                    result = true;
                } catch (error) {
                    console.log('RefreshToken Error: ' + error);
                    resolve(false);
                }

                await AppAPI.logStatistic();
                isRefreshing = false;

                // Resolve all promises in the queue
                while (queue.length > 0) {
                    const resolve = queue.shift();
                    if (resolve) {
                        resolve(result);
                    }
                }
            })();
        });
    },

    logout: async (fullLogout = true) => {
        const store = useStore();

        if (Capacitor.getPlatform() != 'web') {
            PushNotifications.removeAllDeliveredNotifications();
        }

        store.changeActivePage('/home');

        try {
            await AppAPI.deactivateDevice(store.PushNotificationToken);
        } catch (error) {
            console.log(error);
        }

        await OAuth2Client.logout(oauth2Options);

        if (Capacitor.getPlatform() != 'web') {
            await Intercom.hideLauncher();
            await Intercom.logout();
        }

        await AppFunctions.clearSecureStorage();
        await updateAuthTokens('', '');
        await AppFunctions.clearLocalStorage();

        if (fullLogout) {
            if (Capacitor.getPlatform() === 'web') {
                if (process.env.VUE_APP_URL_BASE == 'https://127.0.0.1:8000') {
                    window.location.href = process.env.VUE_APP_URL_BASE + '/logout/';
                    window.location.href = process.env.VUE_APP_REDIRECT_URL;
                } else {
                    window.location.href = process.env.VUE_APP_URL_BASE + '/logout/';
                }
            } else {
                Browser.open({ url: process.env.VUE_APP_URL_BASE + '/logout/' });
                Browser.addListener('browserPageLoaded', async function () {
                    Browser.close();
                });
            }

            router.replace('/home');
        }
        await SplashScreen.hide();
    },

    updateScopes: async () => {
        const store = useStore();
        if (accessToken.value) {
            const response = await AppAPI.getScopes();
            store.changeScope(response.data);
        }
    },
};
